// Initialize first
@import '../init';

// Style Here
.not-found {
  position: relative;
  height: auto;
  min-height: 100vh;
  overflow: hidden;

  .landing {
    background-color: $color-white;
    position: relative;
    z-index: 1;
    height: auto;
    min-height: inherit;

    // Pattern Settings
    .pattern {
      position: absolute;
      width: 1868px;
      height: 752px;
      left: toRem(-237);
      top: toRem(42);
      background-image: url('../../media/images/patterns-and-ornaments/404-comingsoon-pattern.svg');
      background-size: contain;
      background-position: center;
      z-index: -1;
    }

    // Content Settings
    .container {
      padding: toRem(240) 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: toRem(32);
      text-align: center;
      margin-top: toRem(86);
      width: 100%;

      @include media-breakpoint-down(sm) {
        padding: toRem(160) toRem(20) toRem(300) toRem(20);
      }

      .not-found-img {
        width: 312px;
        height: 120px;
        background-image: url('../../media/images/icons/404.svg');
        background-size: cover;
        background-position: center;

        @include media-breakpoint-down(sm) {
          width: 227px;
          height: 87px;
        }
      }

      .content {
        max-width: 354px;
        display: flex;
        flex-direction: column;
        gap: toRem(24);

        .text-wrap {
          display: flex;
          flex-direction: column;
          gap: toRem(12);

          h3 {
            color: $color-black;
          }
        }
      }
    }
  }
}
